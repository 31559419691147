import $ from 'jquery';
import './App.css';
import Form from './Form.js';
import React, { Component } from 'react';

class App extends Component {
  constructor() {
	super()
  this.emailSignatureTable = React.createRef();
	this.state = {
		'croppedImageUrl': 'https://via.placeholder.com/150X150',
		'full-name': 'Full Name Two',
		'position': 'Position',
		'phone-number': ['+000 00 000 0000'],
		'email': 'user@cynet.com',
		'linkedin': '',
		'facebook': '',
		'twitter': '',
    'schedule-meeting': '',
    copied: false
	};
  }

  handleChange = (e) => {
    const $el = $(e.target);
    let value = $el.val();
    if($el.attr("data-class") === 'phone-number') {
      value = value.split('|');
      value = value.map(item => String(item).trim());
    }

    this.setState({
      [$el.attr("data-class")]:  value
    });
  }

  getCroppedImg = (image, crop, fileName, fileUrl) => {
    const canvas = document.createElement('canvas'),
          scaleX = image.naturalWidth / image.width,
          scaleY = image.naturalHeight / image.height,
          ctx = canvas.getContext('2d');

    canvas.width = crop.width;
    canvas.height = crop.height;

    ctx.beginPath();
    ctx.arc(crop.width / 2, crop.height / 2, (crop.width / 2) - 8, 0, 2 * Math.PI, false);
    ctx.fill();
    ctx.lineWidth = 10;
    ctx.strokeStyle = 'transparent';
    ctx.stroke();
    ctx.clip();
    ctx.closePath();

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );


    new Promise((resolve, reject) => {
    	let str = canvas.toDataURL()
	    str = str.replace('data&colon;image/png;base64,','');

	    resolve(str);
      }).then((img) => {
	    this.setState({croppedImageUrl: img});
    });
  }

  generateSignatureCode = () => {
    let signature = document.querySelector("#signature-table");
    signature = signature.parentElement.innerHTML;
    const codeSignatureHTML = document.querySelector("#code-signature-html");
    codeSignatureHTML.innerHTML = signature;
    codeSignatureHTML.style.display = 'block';
  }

  displayPhoneNumbers = (phoneNumbers) => {
    if(!phoneNumbers.length) { 
      return;
    }

    if(Array.isArray(phoneNumbers)) {
      return phoneNumbers
    }
  }

  render() {
    const { croppedImageUrl } = this.state;
    return (
      <div className="App">
        <main className="App-main">
          <div className="intro">
            <h2>Cynet Signature Generator</h2>
            <p><strong>Step 1: </strong> Enter the necessary details below</p>
            <p><strong>Step 2: </strong> Upload an image by clicking the button on the right</p>
            <p><strong>Step 3: </strong> Adjust the cropping of your uploaded image</p>
            <p><strong>Step 4: </strong> Click on the <strong>Copy Signature</strong> button and paste it into the signature section of Outlook <small>(Settings -> All Outlook Settings -> Email -> Compose & Reply)</small>.</p>

            <hr/>

            <p>If you have any difficulty, please contact Sam Benson - <a href={"mailto:samb@cynet.com"}>samb@cynet.com</a></p>

          </div>
          <Form handleChange={this.handleChange} getCroppedImg={this.getCroppedImg}/>
          <div className="App-signature">
            <table id="signature-table" style={{border: 0}} ref={this.emailSignatureTable}>

              <tbody>
                <tr>
                  <td style={{ verticalAlign: 'baseline', paddingRight: '25px', width: 200, position: 'relative'}}>
                    <style>
                      @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&family=Source+Sans+Pro:wght@600;400&display=swap');
                    </style>

                    <table border={0} style={{border: 0}}>
                      <tbody>
                      <tr>
                        <td align={'right'} style={{'textAlign': 'right'}}>
                          {croppedImageUrl && (
                              <img style={{marginBottom: -20}} width="60" alt="Crop" src={'https://www.cynet.com/wp-content/uploads/2022/04/cloud-top-right.png'} />
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td align={'center'} style={{'textAlign': 'center'}}>
                          {croppedImageUrl && (
                              <img height="120" width="120" style={{height: '120px', width: '120px', borderRadius: '50%', overflow: 'hidden'}} alt="Crop" src={croppedImageUrl} />
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td align={'left'} style={{'textAlign': 'left'}}>
                          <img style={{marginTop: -20}} alt={'Cloud'} width={174} src="https://www.cynet.com/wp-content/uploads/2022/04/cloud-bottom-left.png" />
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </td>

                  <td style={{textAlign: 'left'}}>
                    <table border={0} style={{border:0}}>
                      <tbody>
                      <tr>
                        <td colSpan={5}><p style={{margin: 0, lineHeight: 1, fontSize: '20px', color: '#1E202C', fontWeight: '700', fontFamily: "'Poppins', sans-serif"}} className="full-name">{this.state['full-name']}</p></td>
                      </tr>
                      <tr>
                        <td colSpan={5}> <p style={{margin: 0, fontSize: '14px', lineHeight: '14px', color: '#F9379F', fontWeight: '600', fontFamily: "'Source Sans Pro', sans-serif"}} className="position">{this.state['position']}</p></td>
                      </tr>
                      <tr>
                        <td colSpan={5}>
                          <img alt={'Blank'} height={5} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=" />
                        </td>
                      </tr>

                      { this.displayPhoneNumbers(this.state['phone-number']).map(phoneNumber => <tr><td><a href={"tel:" + phoneNumber} style={{ fontSize: '16px', textDecoration: 'none', margin: 0, textAlign: 'left', fontWeight: '600', color: '#1E202C', fontFamily: "'Source Sans Pro', sans-serif"}}>{phoneNumber}</a></td></tr>) } 

                      <tr>
                        <td style={{width: "100%",}}>
                          {this.state['schedule-meeting'].length > 0 &&
                            <a href={this.state['schedule-meeting']} style={{ fontSize: '14px', fontWeight: '600', color: '#1E202C', textDecoration: 'none'}}>Schedule a time with me</a>
                          }
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={5}><p className="email" style={{ margin: 0, paddingBottom: '16px', fontFamily: "'Source Sans Pro', sans-serif"}}>
                          <a href={'mailto:' + this.state.email} style={{ fontSize: '13px', fontWeight: '400', color: '#1E202C', textDecoration: 'none'}}>{this.state['email']}</a>
                          <span> | </span>
                          <a href="https://www.cynet.com" style={{ fontSize: '13px', fontWeight: '400', color: '#1E202C', textDecoration: 'none'}}>www.cynet.com</a>
                        </p>
                        </td>
                      </tr>
                        <tr>
                          <td width={95} style={{paddingTop: 5}}><img src="https://www.cynet.com/wp-content/uploads/2022/04/cynet-logo-pink.png"  alt="Cynet logo" /></td>
                          {(this.state.linkedin.length > 0 || this.state.facebook.length > 0 || this.state.twitter.length > 0) &&
                            <td style={{borderLeft: '1.5px solid #1E202C'}}><img alt={'Blank'} width={1} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=" /></td>
                          }

                          {(!this.state.linkedin.length > 0 && !this.state.facebook.length > 0 && !this.state.twitter.length > 0) &&
                            <td>&nbsp;</td>
                          }

                            <td>
                              {this.state.linkedin.length > 0 &&
                              <a href={this.state['linkedin']}>
                                <img src="https://www.cynet.com/wp-content/uploads/2022/04/linkedin.png"
                                     alt="linkedin logo"/>
                              </a>
                              }
                            </td>

                            <td>
                              {this.state.facebook.length > 0 &&
                              <a href={this.state['facebook']}>
                                <img src="https://www.cynet.com/wp-content/uploads/2022/04/facebook.png"
                                     alt="facebook logo"/>
                              </a>
                              }
                            </td>


                          <td>
                            {this.state.twitter.length > 0 &&
                            <a href={this.state['twitter']}>
                              <img src="https://www.cynet.com/wp-content/uploads/2022/04/twitter.png"
                                   alt="twitter logo"/>
                            </a>
                            }
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="generate-section">
            <button onClick={(e) => {
              e.preventDefault()
              window.getSelection().removeAllRanges();

              let range = document.createRange();
              range.selectNode(document.getElementById('signature-table'));
              window.getSelection().addRange(range);
              document.execCommand('copy');
              window.getSelection().removeAllRanges();

              this.setState({
                copied: true
              })

              setTimeout(() => {
                this.setState({
                  copied: false
                })
              }, 3000)
            }}>{this.state.copied ? 'Copied!' : 'Copy Signature'}</button>
          </div>
        </main>
      </div>
    );
  }
}

export default App;
