import React from 'react';
import $ from 'jquery';
// import Cropper from 'cropperjs';
// import "cropperjs/dist/cropper.css";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

class Form extends React.Component {
    state = {
        src: null,
        crop: {
            unit: '%',
            width: 30,
            aspect: 1,
        },
    };

    onImageLoaded = image => {
        this.imageRef = image;
    };
    
    onCropComplete = crop => {
        this.makeClientCrop(crop);
    };
    
    onCropChange = (crop, percentCrop) => {
        this.setState({ crop });
    };

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.props.getCroppedImg(
                this.imageRef,
                crop,
                'newFile.jpeg',
                this.fileUrl
            );
            this.setState({ croppedImageUrl });
        }
    }
    
    selectFile = (e) => {
        e.preventDefault();
        $(e.target).closest('.upload-button').find('input[type="file"]').trigger('click');
    }

    previewFile = (e) => {
        const FR = new FileReader();
        FR.onload = () => {
            this.setState({ src: FR.result })
        };
        FR.readAsDataURL(e.target.files[0]);
    }

    render() {
        const { crop, croppedImageUrl, src } = this.state; 
        return (
            <div className="App-form">
            <form className="form">    
                <div className="col">    
                    <div className="form-field">
                        <label htmlFor="full-name">Full Name</label>
                        <input name="full-name" id="full-name" type="text" placeholder="Joe Doe" data-class="full-name" onChange={e => this.props.handleChange(e)} />
                    </div>

                    <div className="form-field">
                        <label htmlFor="position">Position</label>
                        <input name="position" id="position" type="text" placeholder="Tech Support Engineer" data-class="position" onChange={e => this.props.handleChange(e)} />
                    </div>

                    <div className="form-field">
                        <label htmlFor="email">Email</label>
                        <input name="email" id="email" type="email" placeholder="user@cynet.com" data-class="email" onChange={e => this.props.handleChange(e)} />
                    </div>

                    <div className="form-field">
                        <label htmlFor="tel">Phone Number</label>
                        <small style={{marginBottom: '10px'}}>If you want to add more phone numbers, separate them with "|" eg: 1321312 | 31231231</small>
                        <input name="tel" id="tel" type="tel" placeholder="+XXX XX XXX-XX-XX" data-class="phone-number" onChange={e => this.props.handleChange(e)} />
                    </div>

                    <div className="form-field">
                        <label htmlFor="linkedin">LinkedIn</label>
                        <input name="linkedin" id="linkedin" type="text" placeholder="https://www.linkedin.com/in/username" data-class="linkedin" onChange={e => this.props.handleChange(e)} />
                    </div>

                    <div className="form-field">
                        <label htmlFor="facebook">Facebook</label>
                        <input name="facebook" id="facebook" type="text" placeholder="https://www.facebook.com/in/username" data-class="facebook" onChange={e => this.props.handleChange(e)} />
                    </div>

                    <div className="form-field">
                        <label htmlFor="twitter">Twitter</label>
                        <input name="twitter" id="twitter" type="text" placeholder="https://www.twitter.com/in/username" data-class="twitter" onChange={e => this.props.handleChange(e)} />
                    </div>

                    <div className="form-field">
                        <label>Schedule meeting link</label>
                        <input name="schedule-meeting" id="schedule-meeting" data-class="schedule-meeting" onChange={e => this.props.handleChange(e)} />
                    </div>
                   

                </div>

                <div className="col">    
                    <div className="upload-button">
                        <input type="file" hidden onChange={e => this.previewFile(e)}/>
                        <button className="upload-file-button" onClick={e => this.selectFile(e)} >Please select an image</button>

                        {src && (
                            <ReactCrop
                                src={src}
                                crop={crop}
                                ruleOfThirds
                                onImageLoaded={this.onImageLoaded}
                                onComplete={this.onCropComplete}
                                onChange={this.onCropChange}
                                circularCrop="true"
                            />
                        )}
                            {croppedImageUrl && (
                            <img alt="Crop" style={{ maxWidth: '100%' }} src={croppedImageUrl} />
                        )}
                        
                    </div>
                </div>
            </form>
            </div>
        );
    }
}

export default Form;